import { Component, OnDestroy, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { OfferConfirmationComponent } from './components/offer-confirmation/offer-confirmation.component';
import { OfferDeclinedComponent } from './components/offer-declined/offer-declined.component';
import { SharedService } from './_services/shared.service';
import { NgcCookieConsentService, NgcInitializationErrorEvent, NgcInitializingEvent, NgcNoCookieLawEvent, NgcStatusChangeEvent } from 'ngx-cookieconsent';
import { Subscription } from 'rxjs';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import Utils from './_utils/utils';
import { ResponsiveMode } from './enum/responsiveModes';
import { BreakpointObserver, Breakpoints, LayoutModule } from '@angular/cdk/layout';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, 
            RouterOutlet, 
            HomeComponent, 
            OfferConfirmationComponent, 
            OfferDeclinedComponent,
            TranslateModule,
            LayoutModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild(HomeComponent) homeComponent!: HomeComponent;

  title = 'client';
  //keep refs to subscriptions to be able to unsubscribe later
  private popupOpenSubscription!: Subscription;
  private popupCloseSubscription!: Subscription;
  private initializingSubscription!: Subscription;
  private initializedSubscription!: Subscription;
  private initializationErrorSubscription!: Subscription;
  private statusChangeSubscription!: Subscription;
  private revokeChoiceSubscription!: Subscription;
  private noCookieLawSubscription!: Subscription;

  modalActive: boolean = false;
  horizontalView: boolean = true;
  vertialView: boolean = false;
  viewMode: string = ResponsiveMode.horizontal;
  responsiveMode = ResponsiveMode;

  constructor(public sharedService: SharedService,
              private ccService: NgcCookieConsentService,
              private responsive: BreakpointObserver,
              private router: Router, 
              private route: ActivatedRoute,
              private cookieService: CookieService,
              // private translateService: TranslateService
  ){

  }
  ngOnInit(): void {

    this.getCloudflareCookie();

    this.responsive.observe([
      Breakpoints.HandsetPortrait,
    ])
      .subscribe(result => {
        const breakpoints = result.breakpoints;
        if(breakpoints[Breakpoints.HandsetPortrait]){
          this.viewMode = ResponsiveMode.vertical;
          this.vertialView = true;
          this.horizontalView = false;
        }
        else {
          this.viewMode = ResponsiveMode.horizontal;
          this.vertialView = false;
          this.horizontalView = true;        
        }
      });


    // subscribe to cookieconsent observables to react to main events
    this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(
    () => {
      // you can use this.ccService.getConfig() to do stuff...
    });

    this.popupCloseSubscription = this.ccService.popupClose$.subscribe(
    () => {
      // you can use this.ccService.getConfig() to do stuff...
    });

    this.initializingSubscription = this.ccService.initializing$.subscribe(
    (event: NgcInitializingEvent) => {
      // the cookieconsent is initilializing... Not yet safe to call methods like `NgcCookieConsentService.hasAnswered()`
      console.log(`initializing: ${JSON.stringify(event)}`);
    });
  
    this.initializedSubscription = this.ccService.initialized$.subscribe(
    () => {
      // the cookieconsent has been successfully initialized.
      // It's now safe to use methods on NgcCookieConsentService that require it, like `hasAnswered()` for eg...
      console.log(`initialized: ${JSON.stringify(event)}`);
    });

    this.initializationErrorSubscription = this.ccService.initializationError$.subscribe(
    (event: NgcInitializationErrorEvent) => {
      // the cookieconsent has failed to initialize... 
      console.log(`initializationError: ${JSON.stringify(event.error?.message)}`);
    });

    this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
    (event: NgcStatusChangeEvent) => {
      // you can use this.ccService.getConfig() to do stuff...
    });

    this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
    () => {
      // you can use this.ccService.getConfig() to do stuff...
    });
 

    // this.translateService.addLangs(['en']); // , 'fr'
    // this.translateService.setDefaultLang('en');
    // this.translateService.use('en'); // Set the initial language

    // // translate example use
    // // <p>{{ 'hello' | translate }}</p>
    // // this.translate.use('fr'); // Switch to French

    // const browserLang = this.translateService.getBrowserLang() || 'en';
    // this.translateService.use(browserLang.match(/en|fr/) ? browserLang : 'en');

    // this.translateService//
    //   .get(['cookie.header', 'cookie.message', 'cookie.dismiss', 'cookie.allow', 'cookie.deny', 'cookie.link', 'cookie.policy'])
    //   .subscribe(data => {

    //     this.ccService.getConfig().content = this.ccService.getConfig().content || {} ;
    //     // Override default messages with the translated ones
    //     this.ccService.getConfig().content!.header = data['cookie.header'];
    //     this.ccService.getConfig().content!.message = data['cookie.message'];
    //     this.ccService.getConfig().content!.dismiss = data['cookie.dismiss'];
    //     this.ccService.getConfig().content!.allow = data['cookie.allow'];
    //     this.ccService.getConfig().content!.deny = data['cookie.deny'];
    //     this.ccService.getConfig().content!.link = environment.guestOfferDomain + 'privacyAndTerms';
    //     this.ccService.getConfig().content!.policy = data['cookie.policy'];

    //     this.ccService.destroy(); // remove previous cookie bar (with default messages)
    //     this.ccService.init(this.ccService.getConfig()); // update config with translated messages
    //   });

  }

  ngAfterViewInit(): void {
    // Access the prop_name attribute
    if (this.homeComponent) {
      console.log('HomeComponent prop_name:', this.homeComponent.prop_name);
    }
  }

  ngOnDestroy() {
    // unsubscribe to cookieconsent observables to prevent memory leaks
    this.popupOpenSubscription.unsubscribe();
    this.popupCloseSubscription.unsubscribe();
    this.initializingSubscription.unsubscribe();
    this.initializedSubscription.unsubscribe();
    this.initializationErrorSubscription.unsubscribe();
    this.statusChangeSubscription.unsubscribe();
    this.revokeChoiceSubscription.unsubscribe();
    this.noCookieLawSubscription.unsubscribe();
  }

  getCloudflareCookie() {
    const cfBmCookie = this.cookieService.get('__cf_bm');
    console.log('cfBmCookie', cfBmCookie);

    const allCookies = this.cookieService.getAll();
    console.log('allCookies: ', allCookies); // Returns an object of key-value pairs

  }



}
