import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Offer } from '../../model/offer';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { OfferService } from '../../_services/offer/offer.service';
import { ActivatedRoute, Router} from '@angular/router';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ResponsiveMode } from '../../enum/responsiveModes';
import { SharedService } from '../../_services/shared.service';
import { AuthService } from '../../_services/auth.service';
import { Notifications } from '../../enum/notifications';
import { Observable, shareReplay } from 'rxjs';
import { GuestDTO } from '../../model/guestDTO';

@Component({
  selector: 'app-offer-photo-terms',
  standalone: true,
  imports: [ 
    CommonModule,  
  ],
  templateUrl: './offer-photo-terms.component.html',
  styleUrl: './offer-photo-terms.component.css'
})
export class OfferPhotoTermsComponent implements OnInit {

  @Input()
  offer!: Offer | null;
  @Input()
  modalRef!: BsModalRef;

  private propertyId: string = '';
  private offerAliasId: string = '';
  acknowledgment: boolean = false;
  viewMode: string = ResponsiveMode.horizontal;
  responsiveMode = ResponsiveMode;

  constructor(private authService: AuthService,
              private offerService: OfferService,
              private responsive: BreakpointObserver,
              private router: Router,
              private route: ActivatedRoute,
              public sharedService: SharedService
              ){
  }

  ngOnInit(): void {

    this.propertyId = this.route.snapshot.paramMap.get('prop_id') ? this.route.snapshot.paramMap.get('prop_id') as string : '';
    this.offerAliasId = this.route.snapshot.paramMap.get('alias_id') ? this.route.snapshot.paramMap.get('alias_id') as string : '';

    this.responsive.observe([
      Breakpoints.HandsetPortrait,
      Breakpoints.HandsetLandscape,
      Breakpoints.TabletPortrait,
      Breakpoints.TabletLandscape,
      Breakpoints.Medium,
      Breakpoints.Small

    ])
      .subscribe(result => {
        const breakpoints = result.breakpoints;
        if(breakpoints[Breakpoints.HandsetPortrait]){
          this.viewMode = ResponsiveMode.vertical;
        }else {
          this.viewMode = ResponsiveMode.horizontal;
        }
      });
  }

  toggleAcknowledgment(){
    this.acknowledgment = !this.acknowledgment;
  }

  getGuestInfo(offer_alias_id: string, prop_id: string): Observable<GuestDTO>{
    return this.offerService.getGuestInfo(offer_alias_id, prop_id).pipe(shareReplay(1));  
  }

  acceptOffer() {
    if(this.offer && this.acknowledgment){

      setTimeout(() => {
        this.closeModal();
      }, 0)
      localStorage.setItem('offer', JSON.stringify(this.offer));



      let guestInfo$ = this.getGuestInfo(this.offerAliasId, this.propertyId).pipe(shareReplay(1));
      let guest_info: GuestDTO = {} as GuestDTO;
      guestInfo$.subscribe(res => {
          guest_info = res;
          this.sharedService.setHasGuestEmail(guest_info.has_guest_email);

          if(this.offer){
            if(this.offer.confirmation_type === Notifications.email &&
              guest_info.has_guest_email === false) {
              this.closeModal();            
              this.navigateToOfferFormEmail();
            } else if(this.offer.confirmation_type === Notifications.email ||         
              this.offer.confirmation_type === Notifications.emailOrSms) {
              this.authService.requestUpgradeToken(this.offerAliasId, this.propertyId)
              .subscribe(res => {
                this.closeModal();
                this.navigateToOfferTokenValidation();
              });
            } else {                
              this.offerService.setOfferAccepted(this.offer)
                              .subscribe(res => {
                                this.closeModal();
                                this.navigateToOfferConfirmation();
                              });
            }
          }
        });

    }
    else{
      // WARN USER SOMETHING WENT WRONG
    }
  }

  closeModal(){
    this.modalRef.hide();

    // Clean up ng-modals modal-open class which disables the scroll if not removed
    document.body.style.overflow = 'auto';
    document.documentElement.style.overflow = 'auto';
    document.documentElement.classList.remove("modal-open"); 
    document.body.classList.remove("modal-open"); 
    // $('body').removeClass("modal-open");
  }

  navigateToOfferConfirmation(){
    this.router.navigate(['/offerConfirmation/' + this.offerAliasId + '/' + this.propertyId], { relativeTo: this.route })
  }

  navigateToOfferTokenValidation(){
    this.router.navigate(['/offerTokenValidation/' + this.offerAliasId + '/' + this.propertyId], { relativeTo: this.route })
  }

  navigateToOfferFormEmail(){
    this.router.navigate(['/offerGuestEmail/' + this.offerAliasId + '/' + this.propertyId], { relativeTo: this.route })
  }

  notifications = {
    "E": "You'll receive an email with the outcome. If we're unable to fulfill your request, it’s likely due to availability.", 
    "S": "You'll receive an SMS update on whether your upgrade request has been accepted", 
    "ES": "You'll receive an email or SMS update on whether your upgrade request has been accepted", 
    "L": "You'll be given a request status link indicating whether your upgrade request has been accepted.  Additionally, upon check-in, a hotel team member will advise if your upgrade has been accepted.", 
    "N": "Upon check-in, a hotel team member will advise if your upgrade has been accepted.", 
  }

  public getNotification(conf_type: string){
    let key = conf_type as keyof typeof this.notifications;
    return this.notifications[key];
  }

}
