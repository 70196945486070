
<div class="backdrop-blur" (click)="this.closeModal()"></div>
<div *ngIf="offer" >
    <div style="border-radius: 7px;" [ngStyle]="this.sharedService.customStyleMulti(['txtModalTheme', 'bodyModalTheme'])">

        <div class="modal-header" style="border: none !important; height: 40px !important;" [ngStyle]="this.sharedService.customStyleMulti(['txtModalTheme', 'bodyModalTheme'])">
            <p></p>
            <div class="button-close" (click)="closeModal()">                
                <img  src='{{ "assets/svg/close-70" + this.sharedService.iconColorClose + ".svg" }}' class="image-close"/>
            </div>
        </div>

        <div style="width: 90%; height: 500px; margin-left: auto; margin-right: auto;">
            <div                  
            style="height: 90%; width: 100%; overflow-y: scroll; scrollbar-width: thin; margin-top: 50px;"
            [ngStyle]="this.sharedService.customStyleMulti(['txtModalTheme', 'bodyModalTheme',  'scrollModalTheme'])"
            >
                <div style="width: 95%;">

                    <p class="terms-txt"  [ngStyle]="this.sharedService.customStyle('txtModalTheme')">Dear Guest,</p>
                    
                    <p class="terms-txt" [ngStyle]="this.sharedService.customStyle('txtModalTheme')">
                        Thank you for considering an upgrade to a {{offer.amenities.upsell_room_name | titlecase}} room for an additional {{offer.ofr_price | currency:'USD' }} per night, plus tax. This fee will be added to your current room rate.</p>
                    
                    <p class="terms-txt" style="font-weight: bold;" [ngStyle]="this.sharedService.customStyle('txtModalTheme')">Here's what happens next:</p>                                    

                    <div style="display: flex;">
                        <li [ngStyle]="this.sharedService.customStyle('txtModalTheme')"></li>
                        <p class="terms-txt" [ngStyle]="this.sharedService.customStyle('txtModalTheme')"><span class="terms-txt" style="font-weight: bold;" [ngStyle]="this.sharedService.customStyle('txtModalTheme')">Review: </span>The property team will review your request typically within 24 hours.</p>
                    </div>    
                    <div style="display: flex;" >
                        <li [ngStyle]="this.sharedService.customStyle('txtModalTheme')"></li>
                        <p class="terms-txt" [ngStyle]="this.sharedService.customStyle('txtModalTheme')"><span class="terms-txt" style="font-weight: bold;" [ngStyle]="this.sharedService.customStyle('txtModalTheme')">Notification: </span>{{getNotification(this.offer ? this.offer.confirmation_type : 'E')}}</p>
                    </div>   
                    <div style="display: flex;">
                        <li [ngStyle]="this.sharedService.customStyle('txtModalTheme')"></li>
                        <p class="terms-txt" [ngStyle]="this.sharedService.customStyle('txtModalTheme')"><span class="terms-txt" style="font-weight: bold;" [ngStyle]="this.sharedService.customStyle('txtModalTheme')">Policy: </span>Once accepted, the charge is non-refundable, so please ensure it aligns with your plans.</p>
                    </div>    

                    <p class="terms-txt" [ngStyle]="this.sharedService.customStyle('txtModalTheme')">We appreciate your stay with us and look forward to making your visit special.</p>
 
                    <p class="terms-txt" [ngStyle]="this.sharedService.customStyle('txtModalTheme')">Warm regards,</p>
                    
                    <p class="terms-txt" [ngStyle]="this.sharedService.customStyle('txtModalTheme')">{{offer.prop_name | titlecase}}</p>
                </div>            
            </div>
        </div>                
        <div class="modal-footer" [ngStyle]="this.sharedService.customStyleMulti(['txtModalTheme', 'bodyModalTheme'])">
            <div style="width: 100%;" style="margin-left: auto; margin-right: 0px; right: float;" [ngStyle]="{'display' : this.viewMode === 'vertical' ? 'block' : 'flex'}" >
                <label style="display: flex; margin-left: auto; margin-right: 0px; right: float;" [ngStyle]="this.sharedService.customStyle('txtModalTheme')">                    
                    <p style="margin-top: 9px; margin-left: auto; margin-right: 0px; right: float; font-weight: bold;">I understand and agree to the upgrade terms.&nbsp;&nbsp;</p>
                    <div style="margin-left: auto; margin-right: 0px; right: float;" [ngStyle]="{'margin-right' : this.viewMode === 'vertical' ? '0px' : '15px'}" >                        
                        <input type="checkbox" style="margin-left: 0px; margin-top: 15px; position: absolute;" (click)="toggleAcknowledgment()">
                        &nbsp;<span class="checkmark" style="margin-bottom: -2px !important; margin-right: 10px;" ></span>
                    </div>
                </label>
                    <button   data-dismiss="modal" type="button" 
                    class="button-request button-request-terms" 
                    [ngStyle]="{'background-color': !this.acknowledgment ? 'rgba(221,29,84,0.75)' : 'rgba(221,29,84,1)' }"
                    style=" margin-left: auto; margin-right: 14px; text-shadow: 0 0 0px #ffffff; min-width: 180px; height: 40px; color: whitesmoke !important;"                    
                    (click)="this.acceptOffer()">REQUEST UPGRADE</button>
            </div>
        </div>            
    </div>
</div>
