<ngx-spinner [ngStyle]="this.sharedService.customStyle('spinnerTheme')" type="square-jelly-box"><h3 [ngStyle]="this.sharedService.customStyle('spinnerTheme')">Loading...</h3></ngx-spinner>
<div [ngStyle]="this.sharedService.customStyle('bodyTheme')" style="height: auto;"  *ngIf="{offerConfirmation: offerConfirmation$ | async, error: offerErrors$ | async} as data" >                        
    <div [hidden]="data.error ? true : false" >
        <div *ngIf="data.offerConfirmation" style="width: 100vw; height: 100vh; display: block">
            <mat-card [ngStyle]="this.sharedService.customStyle('cardTheme')"
                style="                    
                    /* vertical center */
                    margin: 0;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    /* vertical center */

                    padding-left: 50px;
                    padding-right: 50px;
                    padding-bottom: 50px;
                    padding-top: 50px;

                    width: 60%;
                    min-width: 380px !important;
                    height: auto;
                    max-height: 221 !important;
                    font-size: large;
                    text-align: center;"

                    class="not-available-text"   
                >
                <p [ngStyle]="this.sharedService.customStyle('cardTheme')">Thank you for placing your request!</p>
            
                <!-- <p [ngStyle]="this.sharedService.customStyle('cardTheme')">
                    Your request to upgrade to the {{this.sharedService.offer.amenities.room_category_name}} for {{this.sharedService.offer.ofr_price | currency:'USD' }} per night + tax has been placed! 
                    You should receive an email confirming your request shortly. You may also check the status of your request <span (click)="this.navigateToOfferStatus()">here.</span>
                </p> -->

                <p [ngStyle]="this.sharedService.customStyle('cardTheme')">
                    Your request to upgrade to a <span style="font-weight: bold;">{{data.offerConfirmation.room_category_name | titlecase}}</span> room for {{data.offerConfirmation.ofr_price| currency:'USD' }} per night + tax has been placed! 
                    You should receive an email confirming your request shortly. You may also check the status of your request <span (click)="this.navigateToOfferStatus()" style="text-decoration: underline; cursor: pointer; color: #2a9fd6"> here</span>.
                </p>                

                <p [ngStyle]="this.sharedService.customStyle('cardTheme')">
                    The property team will typically review and accept requests within 24 hours of submission and you will be notified via an email communication. 
                    If a request is declined, this is typically due to extenuating inventory circumstances preventing the upgrade at this time, but you may still ask about available upgrades upon check-in!
                </p> 

            </mat-card>

        </div>

    </div>
    <app-offer-not-available [hidden]="data.error ? false : true"></app-offer-not-available>                    
</div> 
