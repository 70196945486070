<ngx-spinner [ngStyle]="this.sharedService.customStyle('spinnerTheme')" type="ball-grid-pulse" size="medium" [fullScreen]="true">
    <h3 [ngStyle]="this.sharedService.customStyle('spinnerTheme')">Loading...</h3>
  </ngx-spinner>
  <div [hidden]="isLoading" [ngStyle]="this.sharedService.customStyle('bodyTheme')" style="height: auto;" *ngIf="{offers: offers$ | async, error: offerErrors$ | async} as data">
    <div [hidden]="data.error ? true : false">
      <app-offer-card-list-responsive #offerList [offers]="data.offers" [offerCount]="this.offerCount"></app-offer-card-list-responsive>
    </div>
    <app-offer-not-available [hidden]="this.handleError(data.error) ? false : true"></app-offer-not-available>
  </div>
  <!-- color: rgb(89, 89, 89); -->
  <p 
    id="privacy_and_terms"
    style="
      color: rgba(255, 255, 255, 0.60);
      font-size: small; 
      width: 100%; 
      margin: 0 auto;
      text-align: center; 
      display: flex; 
      justify-content: center; 
      align-items: center; 
      flex-wrap: wrap; 
      position: fixed; 
      bottom: 0; 
      left: 0; 
      background-color: rgb(45, 45, 45); 
      height: 40px; 
      z-index: 1000;
      box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1);
    "
    [ngClass]="this.responsiveStyle({ 'vertical': 'other-options-handset', 'horizontal': 'other-options-desktop'})">
    <span style="text-align: center;">Upgrade offers are provided on behalf of {{prop_name | titlecase}} by LuxeHotelUpgrades.com, a trusted hotel services partner. Review our </span>&nbsp;
    <a [ngStyle]="this.sharedService.customStyle('txtAnchorTheme')"  
       class="anchorize" 
       (click)="navigatePrivacy()" 
       style="cursor: pointer; font-size: small; text-align: center;"> Privacy Policy </a>
    &nbsp;<span style="text-align: center;"> and </span>&nbsp;
    <a [ngStyle]="this.sharedService.customStyle('txtAnchorTheme')"  
       class="anchorize" 
       (click)="navigateTerms()" 
       style="cursor: pointer; font-size: small; text-align: center;"> Terms of Service</a>
  </p>