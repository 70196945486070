import { ApplicationConfig, Injectable, Renderer2, importProvidersFrom} from '@angular/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClientXsrfModule, provideHttpClient, withInterceptors, withXsrfConfiguration } from '@angular/common/http';
import { LoadingInterceptor } from './_interceptors/Loading.interceptor';
import { provideServerRendering } from '@angular/platform-server';
import { NgcCookieConsentConfig, provideNgcCookieConsent } from 'ngx-cookieconsent';
import { environment } from '../environments/environment';
import { HttpClient } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import Utils from './_utils/utils';
import { SharedService } from './_services/shared.service';



@Injectable({
  providedIn: 'root'
})
export class AppConfig {
  constructor(private sharedService: SharedService) {
    // Now you can use this.sharedService in your class
  }

  // Example method using SharedService
  exampleMethod() {
    // this.sharedService.someMethod();
  }




  
}


// export 
function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

// overrideHTML docs
// https://www.osano.com/cookieconsent/documentation/javascript-api/
const cookieConfig:NgcCookieConsentConfig = {
  cookie: {
    domain: environment.guestOfferDomain,
    // domain: 'localhost' // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
  },
  // palette: {
  //   popup: {
  //     background: 'rgb(45, 45, 45);'
  //   },
  //   button: {
  //     background: '#dd1d54'
  //   }
  // },
  // theme: 'edgeless',
  // type: 'opt-out',
  // layout: 'my-custom-layout'

  overrideHTML: `

<div  role="dialog" aria-live="polite" aria-label="cookieconsent" aria-describedby="cookieconsent:desc" class="cc-window cc-banner cc-type-opt-out cc-theme-edgeless cc-bottom" 
style="background-color: rgb(45, 45, 45); height: 65px;">
  <!--googleoff: all-->
  <span id="cookieconsent:desc" class="cc-message" style="color:white">By using this site, you acknowledge that you have read and understand the  
    <a style="color:#2a9fd6;" aria-label="learn more about privacy policy" tabindex="0" class="cc-link" href="${environment.guestOfferDomain}privacyPolicy" target="_blank" rel="noopener">privacy policy</a>&nbsp;and <a style="color:#2a9fd6;"aria-label="learn more about terms of service" tabindex="0" class="cc-link" href="${environment.guestOfferDomain}termsOfService" target="_blank" rel="noopener">terms of service</a>
  </span>
  <div class="cc-compliance cc-highlight">
    <button aria-label="deny cookies" role="button" tabindex="0" class="cc-btn cc-deny cc-btn-custom cc-deny-custom">Decline</button>   
    <button style="margin-left: 15px; margin-right: 15px;" aria-label="allow cookies" role="button" tabindex="0" class="cc-btn cc-allow cc-btn-custom cc-allow-custom">Allow cookies</button>
  </div>
  <!--googleon: all-->
</div>
  
  ` 
  
  ,content:{
    message: 'Upgrade offers are provided on behalf of [hotel] by LuxeHotelUpgrades.com, a trusted hotel services partner.  Review our Privacy Policy and Terms of Service. ',  
    link: ' <a aria-label="learn more about cookies" tabindex="0" class="cc-link" href="'+ environment.guestOfferDomain + 'privacyAndTerms' +  '" target="_blank" rel="noopener">privacy policy.</a>' 
  }



  // EXAMPLE CUSTOM LAYOUT

  // , layouts: {
  //   "my-custom-layout": '{{messagelink}}{{compliance}}'
  // },
  // elements:{
  //   messagelink: `
  //   <span id="cookieconsent:desc" class="cc-message">{{message}} 
  //     <a aria-label="learn more about cookies" tabindex="0" class="cc-link" href="{{cookiePolicyHref}}" target="_blank" rel="noopener">{{cookiePolicyLink}}</a>, 
  //     <a aria-label="learn more about our privacy policy" tabindex="1" class="cc-link" href="{{privacyPolicyHref}}" target="_blank" rel="noopener">{{privacyPolicyLink}}</a> and our 
  //   </span>
  //   <button>REQUEST
  //   </button>     
  //   `,
  // }
  // , content:{
  //   message: 'By using our site, you acknowledge that you have read and understand our ',
    
  //   cookiePolicyLink: 'Cookie Policy',
  //   cookiePolicyHref: environment.guestOfferDomain + 'privacyAndTerms',

  //   privacyPolicyLink: 'Privacy Policy',
  //   privacyPolicyHref: environment.guestOfferDomain + 'privacyAndTerms',
  // }

};


// If this is defined, then it is used as the inner html instead of layout. This allows for ultimate customisation. 
// Be sure to use the classes cc-btn and cc-allow, cc-deny or cc-dismiss. They enable the app to register click handlers. 
// You can use other pre-existing classes too. See src/styles folder.


export const appConfig: ApplicationConfig = {
  providers: [provideRouter(routes), 
              provideClientHydration(), 
              provideAnimations(), 
              provideHttpClient(withInterceptors([LoadingInterceptor])),
              provideNgcCookieConsent(cookieConfig),
              {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
              },
              {
                provide: TranslateModule,
                useFactory: (translateLoader: TranslateLoader) => TranslateModule.forRoot({ loader: { provide: TranslateLoader, useValue: translateLoader } }),
                deps: [TranslateLoader]
              }
            ]
};




