import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { MatCommonModule } from '@angular/material/core';
import { MatTabsModule } from '@angular/material/tabs';  
import { OfferCardListComponent } from '../offer-card-list/offer-card-list.component';
import { OfferService } from '../../_services/offer/offer.service';
import { Observable, catchError, concat, debounceTime, distinctUntilChanged, forkJoin, fromEvent, ignoreElements, map, merge, of, shareReplay} from 'rxjs';
import { Offer } from '../../model/offer';
import { AuthService } from '../../_services/auth.service';
import { BreakpointObserver, Breakpoints, LayoutModule} from '@angular/cdk/layout';
import { OfferCardListMobileComponent } from '../offer-card-list-mobile/offer-card-list-mobile.component';
import { ActivatedRoute, Router } from '@angular/router';
import { AmenityDTO } from '../../model/amentyDTO';
import { OfferCardListResponsiveComponent } from '../offer-card-list-responsive/offer-card-list-responsive.component';
import { GuestDTO } from '../../model/guestDTO';
import { SharedService } from '../../_services/shared.service';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BusyService } from '../../_services/busy.service';
import { OfferNotAvailableComponent } from '../offer-not-available/offer-not-available.component';
import Utils from '../../_utils/utils';
import { ResponsiveMode } from '../../enum/responsiveModes';



@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    CommonModule, 
    MatCommonModule, 
    MatTabsModule,
    OfferCardListComponent,
    OfferCardListMobileComponent,
    OfferCardListResponsiveComponent,
    LayoutModule, 
    NgxSpinnerModule,
    OfferNotAvailableComponent
  ],
  providers:[ AuthService, OfferService ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.css'
})

export class HomeComponent implements OnInit, AfterViewInit {
  
  offers: Offer[] = [];
  offerCount: number = 0;
  offerAlias$: Observable<Offer[]> = new Observable<Offer[]>;
  offers$: Observable<Offer[]> = new Observable<Offer[]>;
  offerErrors$: any;
  offersSearch$: Observable<Offer[]> = new Observable<Offer[]>;
  offerToggleSearch$: Observable<Offer[]> = new Observable<Offer[]>;
  toggleView: boolean = false;

  @ViewChild(OfferCardListResponsiveComponent) search!: OfferCardListResponsiveComponent;
  @ViewChild(OfferCardListResponsiveComponent) viewToggleButton!: OfferCardListResponsiveComponent;

  isLoading: boolean;
  constructor(
    private offerService: OfferService,
    private authService: AuthService,
    private router: Router, 
    private route: ActivatedRoute,
    public sharedService: SharedService,
    private busyService: BusyService,
     private responsive: BreakpointObserver,
    @Inject(PLATFORM_ID) private platformId: Object 
    ) {

      this.isLoading = true;
  }

  searchToArray(searchStr: string): string[]{
    return searchStr
            .toLocaleLowerCase()
            .split(/[ ,]+/)
            .map(s => s.trim())
            .filter(s => s !== '');
  }

  getSearchSpace(strArray: string[]): string {
    return strArray.reduce( (acc, val) => (acc += ' ' + val)).toLowerCase();
  }

  regEx(str: string, pattern: string): string[]{

    let regex = new RegExp(pattern, "i");
    let match = regex.exec(str);
    if(match) {
      return match;
    }
    return []
  }

  ngAfterViewInit(): void {

    let searchInput:any;
    let viewToggleButton: any;
    searchInput = this.search.searchInput.nativeElement;
    viewToggleButton = this.search.toggleView.nativeElement;

    this.offerToggleSearch$ = fromEvent<any>(viewToggleButton, 'click').pipe(
      shareReplay(1),
      map((event) => { 
        this.toggleView = !this.toggleView;
        if(this.toggleView){
          return this.offers;
        } else {
          return this.filterDefaultAndValidOffers(this.offers).slice(0,3);
        }
      })
    );

    this.offersSearch$ = fromEvent<any>(searchInput, 'keyup')
      .pipe(
        shareReplay(1),
        map((event) =>  event.target.value),
        debounceTime(400),
        distinctUntilChanged(),
        map(searchStr => {

          if(searchStr === '' || searchStr.length < 3){
            return this.filterDefaultAndValidOffers(this.offers).slice(0,3);
          }

          this.busyService.showSpinner();

          setTimeout(() => {
            this.busyService.hideSpinner();                        
          }, 333);

          // let money = this.regEx(searchStr, ',[$]([.,0-9]+),')
          // let sqft = this.regEx(searchStr, ',[$]([.,0-9]+),')            

            let search: string[] = this.searchToArray(searchStr);
            return this.offers
            .filter(offer => {
                let searchSpace = (offer.amenities.upsell_attributes.reduce((acc, val) => (acc += ' ' + val)) + ' ' + offer.amenities.upsell_room_search_description + ' ' + offer.amenities.summary).toLocaleLowerCase();
                
                // return search.every(phrase => {
                return search.some(phrase => {

                  if(phrase.includes('$')){
                      if(this.isNumber(phrase.replace('$', ''))){
                        return Math.round(offer.ofr_price) <= Number(phrase.replace('$', ''));
                      } 
                      return false;
                  } else if(this.isNumber(phrase)) {
                    return offer.amenities.sqft >= Number(phrase);
                  } else {
                    return searchSpace.includes(phrase);
                  }

                });
            })
            .sort(offer => offer.display_order);          

        })
      );
      this.offers$ = merge(concat(this.offers$, this.offersSearch$).pipe(shareReplay(1)), this.offerToggleSearch$).pipe(shareReplay(1));
  }

  isNumber(value?: string | number): boolean
  {
     return ((value != null) &&
             (value !== '') &&
             !isNaN(Number(value.toString())));
  }

  prop_name: string = '';

  ngOnInit(): void {


    this.responsive.observe([
      Breakpoints.HandsetPortrait,
    ])
      .subscribe(result => {
        const breakpoints = result.breakpoints;
        if(breakpoints[Breakpoints.HandsetPortrait]){
          this.viewMode = ResponsiveMode.vertical;
          this.vertialView = true;
          this.horizontalView = false;
        }
        else {
          this.viewMode = ResponsiveMode.horizontal;
          this.vertialView = false;
          this.horizontalView = true;        
        }
      });




    let prop_id =  this.route.snapshot.paramMap.get('prop_id') ? this.route.snapshot.paramMap.get('prop_id') as string : '';
    let offer_alias_id = this.route.snapshot.paramMap.get('alias_id') ? this.route.snapshot.paramMap.get('alias_id') as string : '';
    
    let guestInfo$ = this.getGuestInfo(offer_alias_id, prop_id).pipe(shareReplay(1));
    let amenities$ = this.getAllAmenties(offer_alias_id, prop_id).pipe(shareReplay(1));
    this.offerAlias$ = this.createOfferFromAlias(offer_alias_id, prop_id).pipe(shareReplay(1));

    let data:Observable<any>[] = [];
    data.push(this.offerAlias$);
    data.push(amenities$);
    // data.push(guestInfo$);

    this.offers$ = forkJoin(data)
    .pipe(

      shareReplay(1),
      map(ofr => {        
        if(ofr){

          let ofr_filter = (ofr[0] as Offer[]).filter(ofr => ofr.flg_upsell_available === true && ofr.flg_downgrade === false && ofr.flg_valid_upsell === true);
          this.offerCount = ofr_filter.length; // Total count of all offers before default offer suppression
          let amenities = [] as AmenityDTO[];
          let guest_info = {} as GuestDTO;
          let base_room_category_name = '';
          let prop_name = '';

          if(ofr[1]){
            amenities = ofr[1] as AmenityDTO[];
            base_room_category_name = amenities.filter(a => a.room_category_id === ofr_filter[0].base_room_category_id)[0].room_category_name;
            prop_name = amenities.filter(a => a.room_category_id === ofr_filter[0].base_room_category_id)[0].prop_name;
            this.prop_name = prop_name;
          }

          // if(ofr[2]) {
          //   guest_info = ofr[2] as GuestDTO;
          //   console.log('guest_info', guest_info);
          //   // this.sharedService.guest_email = guest_info;
          // }

          ofr_filter.map(offer => {

            // offer.guest_fname = guest_info.guest_fname ? guest_info.guest_fname : '';
            // offer.guest_lname = guest_info.guest_lname ? guest_info.guest_lname : '';
            // offer.guest_email = guest_info.guest_email ? guest_info.guest_email : '';
            offer.base_room_category_name = base_room_category_name ? base_room_category_name : 'room';
            offer.prop_name = prop_name ? prop_name : 'your hotel';
            offer.offer_alias_id = offer_alias_id;
            
            if(amenities){
              let amenity = amenities.filter(a => a.room_category_id === offer.upsell_room_category_id)[0];
              offer.amenities = {...amenity};              
            } else{
              offer.amenities = {...{...({"upsell_room_name": "Unknown"} as AmenityDTO)}};
            }
          });

          this.isLoading = false;
          return ofr_filter;          
        } 
        return [];       
      }),
      map(offers => {

        let guest_info: GuestDTO = {} as GuestDTO;
        guestInfo$.subscribe(res => {
          guest_info = res;

          offers.map(offer => {

            offer.guest_fname = guest_info.guest_fname ? guest_info.guest_fname : '';
            offer.guest_lname = guest_info.guest_lname ? guest_info.guest_lname : '';            
            offer.has_guest_email = guest_info.has_guest_email;
            this.sharedService.setHasGuestEmail(guest_info.has_guest_email);

          });
          this.offers = offers;
          this.sharedService.offers = this.offers;

        });
        return this.filterDefaultAndValidOffers(offers).slice(0,3);
      }),

    ); 
  

    // let auth$ = this.login().pipe(shareReplay(1));
    // this.offers$ = concat(auth$, this.offers$).pipe(shareReplay(1));

    this.offerErrors$ = this.offers$.pipe(
      shareReplay(1),
      ignoreElements(),
      catchError((err) => {
        // console.log(err);
        return of(err)})
    );
  }

  filterDefaultAndValidOffers(offers: Offer[]): Offer[]{
    if(offers){
      if(offers.filter(ofr => ofr.flg_default_offer === true).length > 0){
        return offers.filter(ofr => ofr.flg_default_offer === true);
      } else if (offers.filter(ofr => ofr.flg_valid_upsell === true).length > 0) {
          return offers.filter(ofr => ofr.flg_valid_upsell === true)
      }
    }
    return offers;
  }

  isError: boolean = true;

  error(){
    this.isError = false;
    return this.isError; 
  }

  invalidOffer(){
    if(this.busyService.isBusy) {return;}
    
    setTimeout(() => {
      if(this.isError){
        this.router.navigate(['/offerNotAvailable'], { relativeTo: this.route });
      }                    
    }, 3000);
  }


  getAllAmenties(offer_alias_id: string, prop_id: string): Observable<AmenityDTO[]>{
    return this.offerService.getAllOfferAmenties(offer_alias_id, prop_id).pipe(shareReplay(1));  
  }
  
  getGuestInfo(offer_alias_id: string, prop_id: string): Observable<GuestDTO>{
    return this.offerService.getGuestInfo(offer_alias_id, prop_id).pipe(shareReplay(1));  
  }

  createOfferFromAlias(offer_alias_id: string, prop_id: string): Observable<Offer[]> {
    return this.offerService.createOfferFromAlias(offer_alias_id, prop_id)
                            .pipe(
                              shareReplay(1), 
                              map(offers => (offers as Offer[])));
  }

  // login(): Observable<any> {
  //   return this.authService.login().pipe(shareReplay(1));
  // }

  checkOffers(offers: Offer[] | null): Offer[] | null{
    if(offers){
      return offers;
    } else{
      return [];
    }
  }

  handleError(error: any){
    this.isLoading = false;
    return error;
  }


  horizontalView: boolean = true;
  vertialView: boolean = false;
  viewMode: string = ResponsiveMode.horizontal;
  responsiveMode = ResponsiveMode;

  responsiveStyle(styleObj: {[key: string]: string}): {[key: string]: boolean} {   
    return Utils.responsiveStyle(this.viewMode, styleObj)
  } 


  navigatePrivacy(){

    const tree = this.router.createUrlTree(['/privacyPolicy'], { relativeTo: this.route });
    const url = this.router.serializeUrl(tree);
    const absoluteUrl = `${window.location.origin}${url}`;
    window.open(absoluteUrl, '_blank');

  }

  navigateTerms(){

    const tree = this.router.createUrlTree(['/termsOfService'], { relativeTo: this.route });
    const url = this.router.serializeUrl(tree);
    const absoluteUrl = `${window.location.origin}${url}`;
    window.open(absoluteUrl, '_blank');
  }




}